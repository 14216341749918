<template>
    <div>
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="top">
            <img class="left" :src="info.picUrl" alt="" />
            <div class="right">
                <div>
                    <div class="title">{{ info.libraryName || info.name }}</div>
                    <div class="tips">
                        <div class="tip">{{ typeName }}</div>
                        <div class="tip" v-if="info.isFree == 1">免费</div>
                    </div>
                    <div class="item">
                        <img src="@/assets/images/Frame-24.png" alt="" />
                        <span>预约人数：{{ info.applyCount || 0 }}人</span>
                    </div>
                    <div class="item">
                        <img src="@/assets/images/Frame-25.png" alt="" />
                        <span>{{ info.libraryAddress || info.address }}</span>
                    </div>
                    <div class="item">
                        <img src="@/assets/images/Frame-26.png" alt="" />
                        <span>{{ info.phoneNumber || '未知' }}</span>
                    </div>
                    <div class="item">
                        <img src="@/assets/images/Frame-27.png" alt="" />
                        <span>{{ info.address }}</span>
                    </div>
                    <div class="item">
                        <img src="@/assets/images/Frame-28.png" alt="" />
                        <span>{{ info.endTime }}</span>
                    </div>
                </div>
                <div class="butts">
                    <div v-if="info.end">
                        <div class="butt" v-if="!info.userCourseTrainingId" @click="openInfoDialog">报名</div>
                        <div class="nbutt" v-else @click="apply">取消报名</div>
                    </div>
                    <div class="nbutt" v-else>活动已结束</div>
                    <div @click="collection" class="butt1">
                        <img :src="!info.collectionId ? require('@/assets/images/Frame-39.png') : require('@/assets/images/Vector.png')" alt="" />
                        <span>{{ info.collectionId ? '已' : '' }}收藏</span>
                    </div>
                    <div @click="praise" class="butt1">
                        <img :src="!info.praiseId ? require('@/assets/images/Frame-40.png') : require('@/assets/images/Frame-50.png')" alt="" />
                        <span>{{ info.praiseId ? '已' : '' }}点赞</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="titles">温馨提示</div>
        <div class="details">{{ info.reminderMsg }}</div>
        <div class="titles">活动详情</div>
        <div style='white-space: pre-wrap;' class="details" v-html="info.activeDetail ? info.activeDetail.replace(/<img/g, '<img style=width:100%;height:auto; ') : ''"></div>
		<booking-info :ruleVisible.sync="infoDialogVisible" @booking="apply" :max-num="1" :flag="false"></booking-info>
    </div>
</template>
<script>
import { delUserCourseTraining, getTrainingDetails, userCourseTraining } from '@/api';
	import bookingInfo from './bookingInfo.vue'
export default {
	components:{bookingInfo},
    name: 'OrderDetail',
    data() {
        return {
            nav: [
                {
                    title: '首页',
                    to: { path: '/' },
                },
                {
                    title: '活动',
                },
                {
                    title: '课程培训',
                    to: { path: '/courseTraining'},
                },
                {
                    title: '培训详情',
                },
            ],
            options: [
                {
                    name: '全部',
                    value: '',
                },
                {
                    name: '演出',
                    value: 0,
                },
                {
                    name: '讲座',
                    value: 1,
                },
                {
                    name: '展览',
                    value: 2,
                },
                {
                    name: '会议',
                    value: 3,
                },
            ],
            info: {},
			infoDialogVisible: false
        };
    },
    computed: {
        typeName: function() {
            let type = this.options[this.info.type];
            if (type) {
                return this.options[this.info.type + 1].name;
            }
            return '';
        }
    },
    created() {
        this.getTrainingDetails();
    },
    methods: {
        getTrainingDetails() {
            getTrainingDetails(this.$route.query.id).then((res) => {
                console.log(res);
                //获取当前时间戳
                const timestamp = Date.parse(new Date().toString());
                //根据日期转换时间戳
                const date = new Date(res.data.endTime);
                if (timestamp > date.getTime()) {
                    res.data.end = false;
                } else {
                    res.data.end = true;
                }
                this.info = res.data;
            });
        },
		openInfoDialog(){
			this.infoDialogVisible = true;
		},
        apply(info={}) {
			this.infoDialogVisible = false;
            if (this.info.userCourseTrainingId) {
                delUserCourseTraining(this.info.userCourseTrainingId).then((res) => {
                    this.$message.success('取消报名成功');
                    this.getTrainingDetails();
                });
            } else {
                userCourseTraining({ courseTrainingId: this.info.id, ...info }).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('报名成功');
                        this.getTrainingDetails();
                    }
                });
            }
        },
        collection() {
            if (this.info.collectionId == null) {
                this.$hao.addPraiseCollection({ relatedId: this.info.id, type: 3 }).then((res) => {
                    this.$message.success('收藏成功');
                    console.log(res);
                    this.info.collectionId = res.data.id;
                });
            } else {
                this.$hao.delPraiseCollection(this.info.collectionId).then(() => {
                    this.$message.success('取消收藏成功');
                    this.info.collectionId = null;
                });
            }
            this.$forceUpdate();
        },
        // 点赞
        praise() {
            if (this.info.praiseId === null) {
                this.$hao.addPraiseCollection({ relatedId: this.info.id, type: 7 }).then((res) => {
                    this.$message.success('点赞成功');
                    this.info.praiseId = res.data.id;
                });
            } else {
                this.$hao.delPraiseCollection(this.info.praiseId).then(() => {
                    this.$message.success('取消点赞成功');
                    this.info.praiseId = null;
                });
            }
        },
    },
};
</script>
<style scoped lang="less">
.top {
    display: flex;
    .left {
        width: 580px;
        height: 385px;
        border-radius: 10px;
    }
    .right {
        flex: 1;
        margin-left: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title {
            font-weight: 700;
            font-size: 20px;
            color: #333333;
            margin-bottom: 8px;
        }
        .tips {
            display: flex;
            margin: 24px 0 36px 0;
            .tip {
                background: #f5f5f5;
                padding: 2px 12px;
                margin-right: 8px;
                font-size: 13px;
                line-height: 19px;
                color: #f33f3e;
                flex: none;
                flex-grow: 0;
                background: rgba(243, 63, 62, 0.1);
                border-radius: 2px;
                order: 1;
            }
        }
        .item {
            display: flex;
            align-items: center;
            margin-top: 16px;
            img {
                width: 20px;
                height: 20px;
                margin-right: 8px;
            }
            span {
                font-size: 16px;
                color: #333333;
                flex: none;
                order: 1;
                flex-grow: 0;
            }
        }
        .butts {
            display: flex;
            align-items: flex-end;
            .butt {
                padding: 12px 110px;
                background: #eda200;
                box-shadow: 0 2px 4px rgba(14, 79, 71, 0.2);
                border-radius: 4px;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                color: #ffffff;
                flex: none;
                order: 0;
                flex-grow: 0;
                margin-top: 37px;
                width: max-content;
            }
            .nbutt {
                padding: 12px 110px;
                background: #e6e6e6;
                box-shadow: 0 2px 4px rgba(14, 79, 71, 0.2);
                border-radius: 4px;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                color: #ffffff;
                flex: none;
                order: 0;
                flex-grow: 0;
                margin-top: 37px;
                width: max-content;
            }
            .butt1 {
                display: flex;
                flex-direction: column;
                margin-left: 24px;
                align-items: center;
                img {
                    width: 20px;
                    height: 20px;
                    margin-bottom: 2px;
                }
                span {
                    font-size: 12px;
                    line-height: 17px;
                    color: #666666;
                    flex: none;
                    order: 1;
                    flex-grow: 0;
                }
            }
        }
    }
}
.titles {
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    color: #333333;
    margin: 24px 0 12px 0;
}
.details {
    font-size: 16px;
    line-height: 23px;
    color: #666666;
}
.details:last-child {
    margin-bottom: 150px;
}
</style>
